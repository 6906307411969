import { EventEmitter } from 'eventemitter3'

const authEventBus = new EventEmitter()

export const AUTH_EVENT_BUS = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
}

export default authEventBus
