import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidebarShow: true,
  sidebarUnfordable: false,
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarShow = !state.sidebarShow
    },
    setSidebarVisible: (state, action) => {
      state.sidebarShow = action.payload
    },
    setSidebarUnfordable: (state, action) => {
      state.sidebarUnfordable = action.payload
    },
  },
})

// Dynamically export all action creators individually
export const { toggleSidebar, setSidebarVisible, setSidebarUnfordable } = dashboardSlice.actions

const dashboardReducer = dashboardSlice.reducer
export default dashboardReducer
