import { api as httpClient } from './httpClient'

/**
 * @type {import('@reduxjs/toolkit/query').BaseQueryFn}
 */
const rtkQueryFn = async (args, api, extraOptions) => {
  try {
    const data = await httpClient(args)
    return { data }
  } catch (error) {
    return { error }
  }
}

export default rtkQueryFn
