import axios from 'axios'
import { genericErrorToast } from 'src/helpers/toast'

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/console`,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use(
  (config) => {
    // other config ...
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  async (response) => {
    try {
      if (response.data === '') {
        return response
      } else {
        const jsonData =
          typeof response.data === 'object' && response.data !== null
            ? response.data
            : JSON.parse(response.data)
        return {
          ...jsonData,
          status: response.status,
        }
      }
    } catch (error) {
      genericErrorToast.fire()
      throw new Error('Failed to parse response as JSON')
    }
  },
  (error) => {
    if (error.response) {
      console.error('Server Error:', error.response.data)
    } else if (error.request) {
      console.error('Network Error:', error.request)
    } else {
      console.error('Error:', error.message)
    }
    return Promise.reject(error)
  },
)

const httpClient = {
  get: (url, config) => api.get(url, config || {}),
  post: (url, data, config) => api.post(url, data || null, config || {}),
  patch: (url, data, config) => api.patch(url, data || null, config || {}),
  delete: (url, config) => api.delete(url, config || {}),
}

export default httpClient
