import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isOrganisationRoute: false,
  isPermissionRoute: false,
}

export const routesSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setIsOrganisationRoute: (state, action) => {
      state.isOrganisationRoute = action.payload
    },
    setIsPermissionRoute: (state, action) => {
      state.isPermissionRoute = action.payload
    },
  },
})

export const { setIsOrganisationRoute, setIsPermissionRoute } = routesSlice.actions

const routesReducer = routesSlice.reducer
export default routesReducer
