import { createApi } from '@reduxjs/toolkit/query/react'
import rtkQueryFn from 'src/helpers/rtkQueryclient'

export const permissionPoliciesApi = createApi({
  reducerPath: 'permissionPoliciesApi',
  baseQuery: rtkQueryFn,
  endpoints: (builder) => ({
    getAllPolicies: builder.query({
      query: ({ id, page, records }) => ({
        url: `/organisations/${id}/access_policies/?page=${page}&records_per_page=${records}`,
        mode: 'cors',
        method: 'GET',
      }),
      transformResponse: (response) => ({
        policies: response.data.policies,
        meta: response.meta,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
        } catch (error) {
          const serializableError = {
            message: error.message,
            status: error.status || 'unknown',
          }
        }
      },
    }),
    getOnePolicy: builder.query({
      query: ({ id, policy_id }) => ({
        url: `/organisations/${id}/access_policies/${policy_id}/`,
        mode: 'cors',
        method: 'GET',
      }),
      transformResponse: (response) => response.data.policy,
      providesTags: (result, error, arg) => {
        if (error || !result) return []
        return ['Policies', { type: 'Policies', id: result.id }]
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
        } catch (error) {
          const serializableError = {
            message: error.message,
            status: error.status || 'unknown',
          }
        }
      },
    }),
  }),
})

export const { useGetAllPoliciesQuery, useGetOnePolicyQuery } = permissionPoliciesApi
