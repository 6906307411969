import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { GridLoader } from 'react-spinners'
import { CContainer } from '@coreui/react'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18next'
import translations from './translations'
import { Admin } from './views/admin/Admin'

i18n.init({
  resources: translations,
  lng: localStorage.getItem('i18nextLng') || navigator.language,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // Disable HTML escaping
  },
  // Add cache to the i18next configuration
  detection: {
    order: ['localStorage'],
    caches: ['localStorage'], // Enable caching with localStorage
    preload: ['en', 'jp', 'vi'], // Specify the languages to preload from cache
  },
})

const loading = (
  <CContainer style={{ height: '100vh', width: '100vw' }}>
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <GridLoader color="#36d7b7" />
    </div>
  </CContainer>
)
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Logout = React.lazy(() => import('./views/pages/logout/Logout'))
const RegisterVerify = React.lazy(() => import('./views/pages/verification/RegistrationVerify'))
const UpdateEmailVerify = React.lazy(() => import('./views/pages/verification/UpdateEmailVerify'))
const InviteVerify = React.lazy(() => import('./views/pages/verification/InvitationVerify'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ResetPassword = React.lazy(() => import('./views/pages/forgotpassword/ResetPassword'))
const SignUpVerificationPage = React.lazy(() =>
  import('./views/pages/register/SignUpVerificationPage'),
)

class App extends Component {
  render() {
    return (
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Suspense fallback={loading}>
            <Routes>
              {/* <Route exact path="/login" name="Login Page" element={<Login />} /> */}
              <Route exact path="/logout" name="Logout" element={<Logout />} />
              {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
              <Route exact path="/verify/:token" name="" element={<RegisterVerify />} />
              <Route exact path="/register/verify" name="" element={<SignUpVerificationPage />} />
              <Route
                exact
                path="/update_email/:token"
                name="Update Email"
                element={<UpdateEmailVerify />}
              />
              <Route exact path="/invitation/:token" name="" element={<InviteVerify />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
              {/* <Route
                exact
                path="/forgotpassword"
                name="Forgot Password Page"
                element={<ForgotPassword />}
              /> */}
              <Route
                exact
                path="/reset_password/:token"
                name="Reset Password Page"
                element={<ResetPassword />}
              />
              <Route exact path="/admin-tooling/:secretkey" name="Admin" element={<Admin />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </I18nextProvider>
    )
  }
}

export default App
