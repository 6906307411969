import { createApi } from '@reduxjs/toolkit/query/react'
import rtkQueryFn from 'src/helpers/rtkQueryclient'

export const permissionGroupsApi = createApi({
  reducerPath: 'permissionGroupsApi',
  baseQuery: rtkQueryFn,
  endpoints: (builder) => ({
    getAllGroups: builder.query({
      query: ({ id, page, records }) => ({
        url: `/organisations/${id}/access_groups/?page=${page}&records_per_page=${records}`,
      }),
      transformResponse: (response) => ({
        groups: response.data.groups,
        meta: response.meta,
      }),
      providesTags: (result, error, arg) => {
        if (error) return {}
        return ['Groups', { type: 'AllGroups', id: arg.id }, 'Groups']
      },
    }),
    getOneGroup: builder.query({
      query: ({ id, group_id }) => ({
        url: `/organisations/${id}/access_groups/${group_id}/`,
      }),
      transformResponse: (response) => ({
        group: response.data.group,
      }),
    }),
    updateGroup: builder.mutation({
      query: ({ id, group_id, payload }) => ({
        url: `/organisations/${id}/access_groups/${group_id}/`,
        mode: 'cors',
        method: 'PATCH',
        data: payload,
      }),
      transformResponse: (response) => {
        return { status: response.status }
      },
      invalidatesTags: (result, error, { group_id }) => [{ type: 'Groups', id: group_id }],
    }),
    createGroup: builder.mutation({
      query: ({ id, group_id, payload }) => ({
        url: `/organisations/${id}/access_groups/`,
        mode: 'cors',
        method: 'POST',
        data: payload,
      }),
      transformResponse: (response) => {
        return { status: response.status }
      },
      invalidatesTags: (result, error, arg) => {
        return ['Groups']
      },
    }),
    deleteGroup: builder.mutation({
      query: ({ id, group_id }) => ({
        url: `/organisations/${id}/access_groups/${group_id}/`,
        mode: 'cors',
        method: 'DELETE',
      }),
      transformResponse: (response) => {
        return { status: response.status }
      },
      invalidatesTags: () => [{ type: 'Groups' }],
    }),
    searchGroupName: builder.query({
      query: ({ id, payload }) => ({
        url: `/organisations/${id}/access_groups/`,
        params: payload,
      }),
      transformResponse: (response) => {
        return {
          groups: response.data.groups,
          meta: response.meta,
        }
      },
    }),
  }),
})

export const {
  useGetAllGroupsQuery,
  useGetOneGroupQuery,
  useUpdateGroupMutation,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useSearchGroupNameQuery,
} = permissionGroupsApi
