import { createApi } from '@reduxjs/toolkit/query/react'
import rtkQueryFn from 'src/helpers/rtkQueryclient'

export const mediaApi = createApi({
  reducerPath: 'mediaApi',
  baseQuery: rtkQueryFn,
  tagTypes: ['Media', 'Tags'], // Add 'Tags' to tagTypes
  endpoints: (builder) => ({
    getMedia: builder.query({
      query: ({ organisationId, page, recordsPerPage }) => {
        return {
          url: `/organisations/${organisationId}/assets/?page=${page}&records_per_page=${recordsPerPage}`,
          method: 'GET',
        }
      },
      providesTags: (result) => [{ type: 'Media', id: 'LIST' }],
      forceRefetch: true,
    }),
    getTags: builder.query({
      query: ({ organisationId, page, recordsPerPage }) => ({
        url: `/organisations/${organisationId}/asset_tags/?page=${page}&records_per_page=${recordsPerPage}`,
        method: 'GET',
      }),
      providesTags: (result) => [{ type: 'Tags', id: 'LIST' }],
    }),
    addMedia: builder.mutation({
      query: ({ organisationId, payload }) => ({
        url: `/organisations/${organisationId}/assets/`,
        method: 'POST',
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: [{ type: 'Media', id: 'LIST' }],
    }),
    updateMedia: builder.mutation({
      query: ({ organisationId, assetId, assetData }) => ({
        url: `/organisations/${organisationId}/assets/${assetId}/`,
        method: 'PATCH',
        data: assetData,
      }),
      invalidatesTags: [{ type: 'Media', id: 'LIST' }],
    }),
    getCertificates: builder.query({
      query: ({ organisationId, page, recordsPerPage }) => ({
        url: `/organisations/${organisationId}/certificates/?page=${page}&records_per_page=${recordsPerPage}`,
        method: 'GET',
      }),
      providesTags: (result) => [{ type: 'Certificates', id: 'LIST' }],
    }),
    addCertificate: builder.mutation({
      query: ({ organisationId, payload }) => ({
        url: `/organisations/${organisationId}/certificates/`,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: [{ type: 'Certificates', id: 'LIST' }],
    }),
    updateCertificate: builder.mutation({
      query: ({ organisationId, certificateId, certificateData }) => ({
        url: `/organisations/${organisationId}/certificates/${certificateId}/`,
        method: 'PATCH',
        data: certificateData,
      }),
      invalidatesTags: [{ type: 'Certificates', id: 'LIST' }],
    }),
    addTag: builder.mutation({
      query: ({ organisationId, payload }) => ({
        url: `/organisations/${organisationId}/asset_tags/`,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: [{ type: 'Tags', id: 'LIST' }],
    }),
    updateTag: builder.mutation({
      query: ({ organisationId, tagId, tagData }) => ({
        url: `/organisations/${organisationId}/asset_tags/${tagId}/`,
        method: 'PATCH',
        data: tagData,
      }),
      invalidatesTags: [{ type: 'Tags', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetTagsQuery,
  useAddMediaMutation,
  useUpdateMediaMutation,
  useGetMediaQuery,
  useGetCertificatesQuery,
  useAddCertificateMutation,
  useUpdateCertificateMutation,
  useAddTagMutation,
  useUpdateTagMutation,
} = mediaApi
export const resetMediaApiState = mediaApi.util.resetApiState
