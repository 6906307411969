import Swal from 'sweetalert2'

const toast = Swal.mixin({
  position: 'top-end',
  showConfirmButton: false,
  toast: true,
  width: 'auto',
})

const timedToast = toast.mixin({
  timer: 3000,
  timerProgressBar: true,

  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  },
})

const genericErrorToast = toast.mixin({
  icon: 'error',
  text: 'Something went wrong. Please try again later.',
})

export { genericErrorToast, toast, timedToast }
