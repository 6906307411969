import { createApi } from '@reduxjs/toolkit/query/react'
import rtkQueryFn from 'src/helpers/rtkQueryclient'

export const memberManagementApi = createApi({
  reducerPath: 'memberManagementApi',
  baseQuery: rtkQueryFn,
  tagTypes: ['Members'],
  endpoints: (builder) => ({
    getMembersOverview: builder.query({
      query: ({ organisationId }) => ({
        url: `/organisations/${organisationId}/access/overview/`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
      providesTags: (result, error, { organisationId }) => [
        { type: 'MembersOverview', id: organisationId },
      ],
    }),
    getGroups: builder.query({
      query: ({ organisationId }) => ({
        url: `/organisations/${organisationId}/access_groups/`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.groups,
    }),
    getPolicies: builder.query({
      query: ({ organisationId, membershipId }) => ({
        url: `/organisations/${organisationId}/memberships/${membershipId}/`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.membership,
      providesTags: (result, error, arg) => {
        if (error) return {}
        return ['Policies', { type: 'Policies', id: arg.membershipId }, 'Policies']
      },
    }),
    getAllPolicies: builder.query({
      query: ({ organisationId }) => ({
        url: `/organisations/${organisationId}/access_policies/?page=1&records_per_page=100`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.policies,
    }),
    sendInvite: builder.mutation({
      query: ({ organisationId, payload }) => ({
        url: `/organisations/${organisationId}/invitations/`,
        mode: 'cors',
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: [{ type: 'Invitations', id: 'LIST' }], // Invalidate pending invitations
    }),
    getMembers: builder.query({
      query: ({ organisationId, page, records }) =>
        `/organisations/${organisationId}/memberships/?page=${page}&records_per_page=${records}`,
      transformResponse: (response) => ({
        memberships: response.data.memberships,
        meta: response.meta,
      }),
      providesTags: (result) => (result ? [{ type: 'Members', id: 'LIST' }] : []),
    }),
    removeMember: builder.mutation({
      query: ({ organisationId, membershipId }) => ({
        url: `/organisations/${organisationId}/memberships/${membershipId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { organisationId }) => [
        { type: 'Members', id: 'LIST' }, // Invalidate the members list
        { type: 'MembersOverview', id: organisationId }, // Invalidate the members overview
      ],
    }),
    getPendingInvitations: builder.query({
      query: ({ organisationId }) => ({
        url: `/organisations/${organisationId}/invitations/`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.invitations,
      providesTags: [{ type: 'Invitations', id: 'LIST' }], // Tag for pending invitations
    }),
    updateMemberPolicies: builder.mutation({
      query: ({ organisationId, membershipId, policies }) => ({
        url: `/organisations/${organisationId}/memberships/${membershipId}/access_policies/`,
        method: 'PATCH',
        data: { policies },
      }),
      invalidatesTags: (result, error, { membershipId }) => [
        { type: 'Policies', id: membershipId }, // Invalidate the specific member's policies
      ],
    }),
    resendInvitation: builder.mutation({
      query: ({ organisationId, invitationId }) => ({
        url: `/organisations/${organisationId}/invitations/${invitationId}/resend/`,
        method: 'POST',
      }),
    }),

    revokeInvitation: builder.mutation({
      query: ({ organisationId, invitationId }) => ({
        url: `/organisations/${organisationId}/invitations/${invitationId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Invitations', id: 'LIST' }], // Invalidate pending members list
    }),
    searchMember: builder.query({
      query: ({ organisationId, payload }) => ({
        url: `/organisations/${organisationId}/memberships/`,
        params: payload,
      }),
      transformResponse: (response) => {
        return {
          memberships: response.data.memberships,
          meta: response.meta,
        }
      },
    }),
  }),
})

export const {
  useGetMembersOverviewQuery,
  useGetGroupsQuery,
  useGetPoliciesQuery,
  useGetAllPoliciesQuery,
  useSendInviteMutation,
  useRemoveMemberMutation,
  useGetMembersQuery,
  useGetPendingInvitationsQuery,
  useUpdateMemberPoliciesMutation,
  useResendInvitationMutation,
  useRevokeInvitationMutation,
  useSearchMemberQuery,
} = memberManagementApi
export const resetMemberManagementApiState = memberManagementApi.util.resetApiState
