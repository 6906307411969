import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Toaster } from 'sonner'
import CircleCheckBold from './assets/icons/Arrows/CircleCheckBold'

const queryClient = new QueryClient()

/**
 * @type {import('sonner').ToasterProps['toastOptions']}
 */
const toastOptions = {
  duration: 3000,
  classNames: {
    content: 'text-sm font-normal',
    title: 'font-normal',
    icon: 'w-auto h-auto m-0',
  },
}
const toastIcons = {
  success: <CircleCheckBold size={36} className="text-brand" />,
}

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Toaster
        className="md:max-w-md max-w-full w-full flex justify-center [&>li]:w-auto [&>li]:ml-auto [&>li]:gap-2"
        position="top-center"
        toastOptions={toastOptions}
        icons={toastIcons}
      />
      <App />
    </QueryClientProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
