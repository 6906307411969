import React from 'react'
import PropTypes from 'prop-types'
/**
 * @param {IconProps} props
 */
const CircleCheckBold = ({ width, height, size = 18, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? size}
      height={height ?? size}
      viewBox="0 0 36 36"
      fill="none"
      {...rest}
    >
      <path
        d="M18 3C15.0333 3 12.1332 3.87973 9.66645 5.52796C7.19972 7.17618 5.27713 9.51886 4.14181 12.2597C3.0065 15.0006 2.70945 18.0166 3.28823 20.9264C3.86701 23.8361 5.29562 26.5088 7.3934 28.6066C9.49119 30.7044 12.1639 32.133 15.0737 32.7118C17.9834 33.2906 20.9994 32.9935 23.7403 31.8582C26.4812 30.7229 28.8238 28.8003 30.472 26.3336C32.1203 23.8668 33 20.9667 33 18C33 16.0302 32.612 14.0796 31.8582 12.2597C31.1044 10.4399 29.9995 8.78628 28.6066 7.3934C27.2137 6.00052 25.5601 4.89563 23.7403 4.14181C21.9204 3.38799 19.9698 3 18 3ZM24.45 14.415L17.595 23.415C17.4553 23.5965 17.2758 23.7436 17.0704 23.845C16.865 23.9464 16.6391 23.9994 16.41 24C16.1822 24.0012 15.957 23.9505 15.7517 23.8518C15.5464 23.753 15.3663 23.6088 15.225 23.43L11.565 18.765C11.4439 18.6094 11.3546 18.4314 11.3022 18.2413C11.2498 18.0512 11.2354 17.8526 11.2598 17.6569C11.2842 17.4612 11.3468 17.2722 11.4442 17.1007C11.5417 16.9293 11.6719 16.7786 11.8275 16.6575C12.1418 16.4128 12.5404 16.303 12.9356 16.3523C13.1313 16.3766 13.3203 16.4393 13.4918 16.5367C13.6633 16.6342 13.8139 16.7644 13.935 16.92L16.38 20.04L22.05 12.54C22.1702 12.3824 22.3202 12.25 22.4915 12.1504C22.6628 12.0508 22.8521 11.9859 23.0485 11.9595C23.2449 11.933 23.4446 11.9455 23.6361 11.9962C23.8277 12.0469 24.0074 12.1348 24.165 12.255C24.3226 12.3752 24.455 12.5252 24.5546 12.6965C24.6542 12.8678 24.7191 13.0571 24.7455 13.2535C24.772 13.4499 24.7595 13.6496 24.7088 13.8411C24.6581 14.0327 24.5702 14.2124 24.45 14.37V14.415Z"
        fill="currentColor"
      />
    </svg>
  )
}

CircleCheckBold.propTypes = {
  width: PropTypes.number || PropTypes.string,
  height: PropTypes.number || PropTypes.string,
  size: PropTypes.number || PropTypes.string,
  color: PropTypes.string,
}

export default CircleCheckBold
