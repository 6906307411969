import { configureStore } from '@reduxjs/toolkit'
import dashboardReducer from './dashboard/slice'
import routesReducer from './routes/slice'
import { organisationsApi, resetOrganisationsApiState } from 'src/services/organisations'
import { checkpointsApi, resetCheckpointsApiState } from 'src/services/checkpoints'
import { permissionPoliciesApi } from 'src/services/permissionPolicies'
import { permissionGroupsApi } from 'src/services/permissionGroups'
import { memberManagementApi, resetMemberManagementApiState } from 'src/services/memberManagement'
import { pbAnalyticsApi } from 'src/services/pbAnalytics'
import { authApis } from 'src/services/auth'
import authEventBus, { AUTH_EVENT_BUS } from 'src/services/events/authEventBus'
import { utilsApi } from 'src/services/utils'
import { batchesApi, resetBatchesApiState } from 'src/services/batches'
import { productsApi, resetProductsApiState } from 'src/services/products'
import { mediaApi, resetMediaApiState } from 'src/services/media'

const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    [organisationsApi.reducerPath]: organisationsApi.reducer,
    [checkpointsApi.reducerPath]: checkpointsApi.reducer,
    [batchesApi.reducerPath]: batchesApi.reducer,
    [authApis.reducerPath]: authApis.reducer,
    [permissionPoliciesApi.reducerPath]: permissionPoliciesApi.reducer,
    [permissionGroupsApi.reducerPath]: permissionGroupsApi.reducer,
    [pbAnalyticsApi.reducerPath]: pbAnalyticsApi.reducer,
    [utilsApi.reducerPath]: utilsApi.reducer,
    [memberManagementApi.reducerPath]: memberManagementApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [mediaApi.reducerPath]: mediaApi.reducer,
    routes: routesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      organisationsApi.middleware,
      checkpointsApi.middleware,
      authApis.middleware,
      permissionPoliciesApi.middleware,
      pbAnalyticsApi.middleware,
      utilsApi.middleware,
      memberManagementApi.middleware,
      batchesApi.middleware,
      productsApi.middleware,
      permissionGroupsApi.middleware,
      mediaApi.middleware,
    ),
})

authEventBus.on(AUTH_EVENT_BUS.LOGOUT, () => {
  store?.dispatch?.(resetOrganisationsApiState())
  store?.dispatch?.(resetCheckpointsApiState())
  store?.dispatch?.(resetMemberManagementApiState())
  store?.dispatch?.(resetBatchesApiState())
  store?.dispatch?.(resetProductsApiState())
  store?.dispatch?.(resetMediaApiState())
})

export default store
